<template>
  <div>
    <div class="between ai-c mb-30">
      <h2>Позиции</h2>

      <VButton
        v-if="permissionCreateTemplatePosition"
        text="Создать позицию"
        icon="create"
        size="sm"
        color="primary-sm"
        @click.native="goToPage('TemplatePositionCreate')"
      />
    </div>

    <VSearch
      placeholder="Поиск по названию позиции и артикулу"
      class="mb-30"
      @request="loadSearch"
    />

    <Spinner v-if="isLoading" />

    <div v-if="currentList.length && !isLoading" class="column gap-s">
      <CardFull
        v-for="item in currentList"
        :key="item.id"
        :state="item"
        :title="getItemName(item)"
        hasDetail
        @detail="goToDetail"
      >
        <div class="mt-30 d-flex gap-m">
          <InfoTextBlock
            label-text="Артикул"
            :text="item.article"
          />
          <InfoTextBlock
            label-text="Тип"
            :text="getTypeName(item)"
          />
          <InfoTextBlock
            label-text="Материал"
            :text="getMaterialName(item)"
          />
        </div>
      </CardFull>
    </div>

    <h2 v-if="!currentList.length && !isLoading">
      Ничего не найдено
    </h2>
  </div>
</template>

<script>
import CardFull from '@/components/ui/CardFull'
import InfoTextBlock from '@/components/ui/AppInfoTextBlock'
import VButton from '@/components/simple/button/VButton'
import VSearch from '@/components/Form/Search'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { goToPage } from '@/utils/router'

export default {
  name: 'TemplatePositionList',
  components: {
    CardFull,
    InfoTextBlock,
    VButton,
    VSearch
  },
  computed: {
    ...mapState('productions/template/position', {
      isLoading: 'isLoading'
    }),
    ...mapGetters('productions/template/position', {
      currentList: 'currentList'
    }),
    ...mapGetters('permission', {
      permissionCreateTemplatePosition: 'permissionCreateTemplatePosition'
    })
  },
  destroyed () {
    this.RESET_STORE()
  },
  methods: {
    ...mapMutations('productions/template/position', {
      RESET_STORE: 'RESET_STORE'
    }),
    ...mapActions('productions/template/position', {
      loadSearch: 'loadSearch'
    }),
    goToPage,
    goToDetail (id) {
      goToPage('TemplatePositionDetail', { id })
    },

    getMaterialName (item) {
      return item?.material?.name ?? '-'
    },

    getTypeName (item) {
      return item?.type?.name ?? '-'
    },

    getItemName (item) {
      return item?.name ?? '-'
    }
  }
}
</script>
